<template>
  <div>
    <b-row v-if="todayLives.length > 0">
      <b-card class="col-sm-12">
        <b-card-header style="font-size: 20px;">
          Lives para hoje:
        </b-card-header>
        <div
          v-for="live in todayLives"
          :key="live.id"
        >
          <span style="font-size: 17px;">{{ live.title }}</span> -
          <b-button
            :disabled="live.ready === 0"
            :href="`/live/${live.slug}`"
            variant="primary"
          >
            Acessar
          </b-button>
        </div>
      </b-card>
    </b-row>
    <b-row class="match-height">
      <b-col
        v-if="module_cursos"
        cols="12"
        sm="5"
        md="4"
        lg="4"
        xl="4"
      >
        <a href="/cursos">
          <home :data="data.cursos" />
        </a>
      </b-col>
      <b-col
        v-if="module_congressos"
        cols="12"
        sm="5"
        md="4"
        lg="4"
        xl="4"
      >
        <a href="/congressos">
          <home :data="data.congressos" />
        </a>
      </b-col>
      <b-col
        v-if="module_lives"
        cols="12"
        sm="5"
        md="4"
        lg="4"
        xl="4"
      >
        <a href="/lives">
          <home :data="data.lives" />
        </a>
      </b-col>
      <b-col
        v-if="module_podcasts"
        cols="12"
        sm="5"
        md="4"
        lg="4"
        xl="4"
      >
        <a href="/podcasts">
          <home :data="data.podcasts" />
        </a>
      </b-col>
      <b-col
        v-if="module_publicacoes"
        cols="12"
        sm="5"
        md="4"
        lg="4"
        xl="4"
      >
        <a href="/publicacoes">
          <home :data="data.publicacoes" />
        </a>
      </b-col>
      <b-col
        v-if="module_noticias"
        cols="12"
        sm="5"
        md="4"
        lg="4"
        xl="4"
      >
        <a :href="url_noticia">
          <home :data="data.noticias" />
        </a>
      </b-col>      
      <b-col
        v-if="module_gamificacao"
        cols="12"
        sm="5"
        md="4"
        lg="4"
        xl="4"
      >
        <a href="/gamificacao">
          <home :data="data.gamificacao" />
        </a>
      </b-col>
      <b-col
        v-if="module_feed"
        cols="12"
        sm="5"
        md="4"
        lg="4"
        xl="4"
      >
        <a href="/feed">
          <home :data="data.feed" />
        </a>
      </b-col>
      <b-col
        v-if="module_estatuto"
        cols="12"
        sm="5"
        md="4"
        lg="4"
        xl="4"
      >
        <a :href="url_estatuto">
          <home :data="data.estatuto" />
        </a>
      </b-col>
      <b-col
        v-if="module_eventos"
        cols="12"
        sm="5"
        md="4"
        lg="4"
        xl="4"
      >
        <a href="/eventos_new">
          <home :data="data.eventos" />
        </a>
      </b-col>
      <b-col
        v-if="module_politica"
        cols="12"
        sm="5"
        md="4"
        lg="4"
        xl="4"
      >
        <a :href="url_policy">
          <home :data="data.politicaDePrivacidade" />
        </a>
      </b-col>
      <b-modal
        id="evt-modal-notification"
        size="lg"
        title="Eventos para hoje"
        ok-only
        no-stacking
      >
        <div>
          Você tem alguns eventos para hoje:
          <div v-if="evtList.length > 0">
            Congresso:
          </div>
          <ul
            v-if="evtList.length > 0"
            id="notification"
            style="overflow-y: scroll;height:220px;"
          >
            <li
              v-for="e in evtList"
              :key="e.id"
            >
              {{ `${e.title} - ${e.start_time.substring(5, 0)}` }}

              <b-button
                variant="info"
                @click="goToUrl(e.congress.path_event, e.slug)"
              >
                Entrar ao vivo
              </b-button>
            </li>
          </ul>
          <div v-if="liveList.length > 0">
            Live:
          </div>
          <ul
            v-if="liveList.length > 0"
            id="notification"
            style="overflow-y: scroll;height:220px;"
          >
            <li
              v-for="l in liveList"
              :key="l.id"
            >
              {{ `${l.title} - ${l.time.substring(5, 0)}` }}

              <b-button
                variant="info"
                @click="goToLiveUrl(l.slug)"
              >
                Entrar ao vivo
              </b-button>
            </li>
          </ul>
        </div>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import Home from '../../../components/dashboard/Home.vue'
import { mapActions } from 'vuex'
import HomeServices from './homeServices'

export default {
  components: {
    Home,
  },
  data() {
    return {
        module_congressos: false,
        module_feed: false,
        module_lives: false,
        module_cursos: false,
        module_podcasts: false,
        module_publicacoes: false,
        module_noticias: false,
        module_certificados: false,
        module_gamificacao: false,
        module_estatuto: false,
        module_eventos: false,
        module_politica: false,
      data: {     
        cursos: [
          {
            coloricon: '#FFF', icon: 'FilmIcon', color: 'primary', title: 'Cursos'
          },
        ],
        podcasts: [
          {
            coloricon: '#FFF', icon: 'HeadphonesIcon', color: 'primary', title: 'Podcasts'
          },
        ],
        congressos: [
          {
            coloricon: '#FFF', icon: 'MonitorIcon', color: 'success', title: 'Congressos'
          },
        ],
        lives: [
          {
            coloricon: '#FFF', icon: 'PlayIcon', color: 'danger', title: 'Lives'
          },
        ],        
        publicacoes: [
          {
            coloricon: '#FFF', icon: 'BookOpenIcon', color: 'warning', title: 'Publicações'
          },
        ],
        noticias: [
          {
            coloricon: '#FFF', icon: 'RssIcon', color: 'info', title: 'Notícias'
          },
        ],
        gamificacao: [
          {
            coloricon: '#FFF', icon: 'StarIcon', color: 'light-primary', title: 'Gamificação'
          },
        ],
        feed: [
          {
            coloricon: '#FFF', icon: 'ScreenIcon', color: 'light-primary', title: 'Feed'
          },
        ],
        estatuto: [
          {
            coloricon: '#FFF', icon: 'FileTextIcon', color: 'light-info', title: 'Estatuto'
          },
        ],
        eventos: [
          {
            coloricon: '#FFF', icon: 'CalendarIcon', color: 'light-success', title: 'Eventos'
          },
        ],
        politicaDePrivacidade: [
          {
            coloricon: '#FFF', icon: 'InfoIcon', color: 'light-success', title: 'Política de privacidade'
          },
        ],
      },
      evtList: [],
      isSpecial: null,
      ordVerify: [],
      todayLives: [],
      allLives: [],
      loading: true,
      title: '',
      url_noticia: '',
      url_estatuto: '',
      url_policy: '',
      userData: '',
      associete: false,
      listAssociete: [],
      item: [],
      payment_status: null,
      speakerLives: [],
      liveList: [],
    }
  },
  computed: {
    speakerEvent() {
      return this.$store.state.congress.speakerEvents
    },
  },
  watch: {
    speakerEvent() {
      const q = new Date()
      const m = q.getMonth() + 1
      const d = q.getDate()
      const y = q.getFullYear()

      const date = new Date(`${y}-${m}-${d} 00:00:00`)

      this.speakerEvent.forEach(element => {
        const evtDate = new Date(`${element.event.date} 00:00:00`)

        if (evtDate.toString() === date.toString()) {
          this.evtList.push(element.event)
        }
      })
      if (this.evtList.length > 0) { this.$bvModal.show('evt-modal-notification') }
    },
    speakerLives() {
      const q = new Date()
      const m = q.getMonth() + 1
      const d = q.getDate()
      const y = q.getFullYear()
      const date = new Date(`${y}-${m}-${d} 00:00:00`)

      this.speakerLives.forEach(element => {
        const liveDate = new Date(`${element.live.date} 00:00:00`)
        if (liveDate.toString() === date.toString()) {
          this.liveList.push(element.live)
        }
      })

      if (this.liveList.length > 0) { this.$bvModal.show('evt-modal-notification') }
    },
    allLives() {
      const q = new Date()
      const m = q.getMonth() + 1
      const d = q.getDate()
      const y = q.getFullYear()
      const date = new Date(`${y}-${m}-${d} 00:00:00`)

      this.allLives.forEach(element => {
        const liveDate = new Date(`${element.date} 00:00:00`)
        if (liveDate.toString() === date.toString()) {
          this.todayLives.push(element)
          console.log(element)
        }
      })
    }
  },
  async mounted() {
    this.module_congressos = process.env.VUE_APP_MODULE_CONGRESSOS === 'true'
    this.module_feed = process.env.VUE_APP_MODULE_FEED === 'true'
    this.module_lives = process.env.VUE_APP_MODULE_LIVES === 'true'
    this.module_cursos = process.env.VUE_APP_MODULE_CURSOS === 'true'
    this.module_podcasts = process.env.VUE_APP_MODULE_PODCASTS === 'true'
    this.module_publicacoes = process.env.VUE_APP_MODULE_PUBLICACOES === 'true'
    this.module_noticias = process.env.VUE_APP_MODULE_NOTICIAS === 'true'
    this.module_certificados = process.env.VUE_APP_MODULE_CERTIFICADOS === 'true'
    this.module_gamificacao = process.env.VUE_APP_MODULE_GAMIFICACAO === 'true'
    this.module_estatuto = process.env.VUE_APP_MODULE_ESTATUTO === 'true'
    this.module_eventos = process.env.VUE_APP_MODULE_EVENTOS === 'true'
    this.module_politica = process.env.VUE_APP_MODULE_POLITICO === 'true'
    await this.ActionGetEventBySpeaker()
    await HomeServices().getLives().then(res => {
      res.forEach(live => {
        this.allLives.push(live)
      })
    })
    this.payment_status = localStorage.getItem('payment_status')
    HomeServices().verifyUserLive().then(api => {
      this.speakerLives = api.response.spekaerLive
    })
  },
  methods: {
    ...mapActions('congress', [
      'ActionGetEventBySpeaker'
    ]),
    showModal() {
      if (this.payment_status === null) {
        this.userData = this.$store.state.auth.user
        this.listAssociete = this.userData.associate
        this.listAssociete.forEach(element => {
          if (element.year === '2022') {
            this.associete = true
          }
        })
        this.$refs['my-modal'].show()
      } else { //
      }
    },
    combo() {
      localStorage.removeItem('items')
      this.item.push({
        name: 'Combo OP3',
        course_id: null,
        congress_id: 1,
        associate_anuity: 1,
        combo_id: 1,
        amount: 1,
        value: 65000
      })
      localStorage.setItem('items', JSON.stringify(this.item))
      window.location.href = '/associado/combo/pagamento'
    },
    goToUrl(congress, slug) {
      window.location.href = `/congress/${congress}/${slug}`
    },
    goToLiveUrl(slug) {
      window.location.href = `/live/${slug}`
    },
  }
}
</script>

<style>
a{
  text-decoration: none;
}
#notification {
  list-style: none;
  margin: 10px 10px;
}

#notification li {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 0;
}

#notification li button {
  margin-left: auto;
}
</style>
